<template>
  <div style='height: 100%'>
    <nesi-user-common>
      <template #content>
        <div class='prev-house-content'>
          <div style='padding: 5px 0'>
            <el-row class='mb-4'>
              <div
                :class="creditForm.creditType == 1 ? 'ed' : ''"
                @click='creditType(1)'
                class='but'
              >
                租赁企业红榜
              </div>
              <div
                :class="creditForm.creditType == 2 ? 'ed' : ''"
                @click='creditType(2)'
                class='but'
              >
                租赁企业黑榜
              </div>
              <div
                :class="creditForm.creditType == 3 ? 'ed' : ''"
                @click='creditType(3)'
                class='but'
              >
                信用公示
              </div>
              <!-- <el-button
                :autofocus="true"
                type="primary"
                @click="creditType(1)"
                plain
                >租赁企业红榜</el-button
              >
              <el-button type="primary" @click="creditType(2)" plain
                >租赁企业黑榜</el-button
              > -->
            </el-row>
            <!-- <el-select
              v-model="creditForm.creditType"
              class="m-2"
              placeholder="Select"
              size="large"
              style="margin-left: 10px"
              @change="creditTypeChange"
            >
              <el-option label="红榜TOP30" value="1" />
              <el-option label="黑榜TOP30" value="2" />
            </el-select> -->
          </div>
          <div class='rent-info-wrapper' style='padding: 10px'>
            <template v-if='creditForm.creditType !== 3'>
              <el-table
                v-loading='loading'
                :data='creditData'
                style='width: 100%'
                :header-cell-style="{ textAlign: 'center' }"
                :cell-style="{ textAlign: 'center' }"
                :border='true'
              >
                <el-table-column label='排名' type='index' width='80'>
                  <template #default='{ row }'>
                    {{ row.bdpm }}
                  </template>
                </el-table-column>
                <el-table-column prop='companyName' label='企业名称' />
                <el-table-column prop='name' label='信用评分' width='450'>
                  <template #default='scope'>
                    <el-progress
                      style='width: 400px'
                      :text-inside='true'
                      :stroke-width='16'
                      :percentage='Number(scope.row.number)'
                      :format='perFormat'
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop='name'
                  :label='creditTableLabel'
                  width='350'
                >
                  <template #default='scope'>
                    <el-progress
                      v-if='scope.row.lastNumber'
                      style='width: 320px'
                      :text-inside='true'
                      :stroke-width='16'
                      :percentage='Number(scope.row.lastNumber)'
                      :format='perFormat'
                    />
                    <span v-else>暂无评分</span>
                  </template>
                </el-table-column>
              </el-table>

              <div
                style='
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                '
              >
                <p style='font-size: 14px; color: orange'>
                  <span style='color: orange'>温馨提醒</span
                  >：按分数从高到底进行排名，如分数相同，则排序相同，企业排名不分先后
                </p>
                <el-pagination
                  style='text-align: right; margin-top: 10px'
                  background
                  layout='total, prev, pager, next'
                  :total='creditDataTotal'
                  @current-change='handleCurrentChange'
                />
              </div>
            </template>
            <template v-else>
              <el-table
                :data='dissentData'
                style='width: 100%'
                :header-cell-style="{ textAlign: 'center' }"
                :cell-style="{ textAlign: 'center' }"
                :border='true'
              >
                <el-table-column label='排名' type='index' width='80'>
                  <template #default='{ $index }'>
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop='fullName' label='企业名称' />
                <el-table-column prop='irregularities' label='信用指标类型' />
                <el-table-column prop='score' label='本次评分' />
                <el-table-column prop='companyName' label='奖惩类型'>
                  惩罚
                </el-table-column>
                <el-table-column prop='fractionNum' label='奖惩次数' />
                <el-table-column prop='name' label='操作' width='120'>
                  <template #default='{ row }'>
                    <el-button
                      type='primary'
                      @click='openBiz(row)'
                      size='small'
                    >
                      发起异议
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>

              <div
                style='
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                '
              >
                <p style='font-size: 14px; color: orange'>
                  <span style='color: orange'>温馨提醒</span
                  >：按分数从高到底进行排名，如分数相同，则排序相同，企业排名不分先后
                </p>
                <el-pagination
                  style='text-align: right; margin-top: 10px'
                  background
                  layout='total, prev, pager, next'
                  :total='total'
                  @current-change='handleDissentChange'
                />
              </div>
            </template>
          </div>
        </div>
      </template>
    </nesi-user-common>
    <!-- 异议备注 -->
    <el-dialog v-model='remarkIsShow' title='提示' width='30%' draggable>
      <el-input
        v-model='textarea'
        :rows='4'
        type='textarea'
        placeholder='请进行备注'
      />
      <template #footer>
        <span class='dialog-footer'>
          <el-button @click=";(remarkIsShow = false), (textarea = '')"
          >取消</el-button
          >
          <el-button type='primary' @click='setCreateBiz()'> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ElMessageBox, ElMessage } from 'element-plus'
import { defineComponent, ref, onMounted, reactive, nextTick } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { creditList, publicityApproved, createBiz } from '@/api/home'

defineComponent({
  NesiUserCommon
})
// 红黑榜
const creditForm = reactive({
  companyType: '3',
  creditType: '1'
})
const creditTableLabel = ref('本轮')
const creditDataAll = ref([])
const creditDataTotal = ref(0) // 总记录数
const loading = ref(false)
const creditData = ref([])
const currentPage = ref(1) // 默认页码为 1
const pagesize = ref(10) // 默认一页显示10条
const getCreditList = async (data) => {
  data.pageNo = currentPage.value
  data.pageSize = pagesize.value
  try {
    loading.value = true
    const { records = [], total = 0, countId = '本轮' } = await creditList(data)
    creditData.value = records.map((item) => {
      return {
        companyName: item.qymc,
        number: item.xypf,
        lastNumber: item.lastXypf,
        bdpm: item.bdpm
      }
    })
    creditTableLabel.value = countId
    creditDataTotal.value = total
  } finally {
    loading.value = false
  }
}
const perFormat = (percentage) => {
  return percentage + '分'
}

const handleCurrentChange = (page) => {
  currentPage.value = page
  getCreditList(creditForm)
}

// const creditTypeChange = (value) => {
//   currentPage.value = 1
//   creditForm.creditType = value
//   getCreditList(creditForm)
// }
function creditType(value) {
  currentPage.value = 1
  pageSize.value = 1
  creditForm.creditType = value
  if (value == 3) {
    getDissent()
  } else {
    getCreditList(creditForm)
  }
}

// 信用公示分页
const handleDissentChange = (page) => {
  pageSize.value = page
  getDissent()
}
const isLoading = ref(false)
const pageNo = ref(1)
const pageSize = ref(10)
const dissentData = ref()
const total = ref()
const remarkIsShow = ref(false)
const textarea = ref()
const rowData = ref()

// 打开异议弹窗
function openBiz(row) {
  console.log(row)
  rowData.value = row
  remarkIsShow.value = true
}

async function setCreateBiz() {
  console.log(rowData.value)
  const obj = {
    // id: rowData.value.id,
    publicityId: rowData.value.id,
    remark: textarea.value
  }
  try {
    const res = await createBiz(obj)
    // console.log(row)
    textarea.value = ''
    ElMessage.success('异议发起成功')
    remarkIsShow.value = false
  } catch (error) {
    textarea.value = ''
    remarkIsShow.value = false
  }
}

// 获取信用公示
async function getDissent() {
  const obj = {
    pageNo: pageNo.value,
    pageSize: pageSize.value
  }
  const res = await publicityApproved(obj)
  // console.log(res)
  dissentData.value = res.records
  total.value = res.total
}

onMounted(() => {
  getCreditList(creditForm)
  // getDissent()
})
</script>
<style lang='scss' scoped>
.but {
  height: 40px;
  width: 160px;
  border: 1px solid #268eff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #268eff;
  cursor: pointer;
}

.ed {
  color: #fff;
  background-color: #268eff;
}

.prev-house-content {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 10px;

  .image {
    width: 100%;
    height: 60px;

    &.third-image {
      height: 120px;
    }
  }

  .title-wrapper {
    background-image: url('https://pc.nnfwzl.com/rent-house/rent-platform-web/title.png');
    background-size: 100% 100%;
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-repeat: no-repeat;
    padding: 0 10px 0 10px;

    &.rent-title-wrapper {
      background-size: 150% 100%;
      height: 40px;
      line-height: 40px;
    }

    .title {
      font-size: 18px;
      line-height: 30px;
      color: #ffffff;
      height: 30px;
      font-weight: 600;
    }

    .more {
      font-size: 14px;
      color: #036eb7;
      font-weight: 800;
      height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .image {
        margin-left: 10px;
        margin-top: 0;
        width: 40px;
        height: 10px;
      }
    }
  }

  .house-list-wrapper {
    .house-item {
      position: relative;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      border: solid 1px #eee;
      padding: 0 10px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 5px;
      cursor: pointer;

      .image {
        width: 300px;
        height: 200px;
      }

      .validate {
        position: absolute;
        left: 10px;
        top: 10px;
        color: #fff;
        font-size: 14px;
        background: #71d5a1;
        padding: 5px 8px;
        font-size: 13px;
        border: 1px solid #71d5a1;
      }

      .house-item-field {
        position: relative;
        margin-left: 10px;
        flex: 1;

        .field {
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color: #666;

          &.field-auto {
            height: auto;
            line-height: auto;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 3; //行数
            line-clamp: 3;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }
        }

        .title {
          padding-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 40px;
          line-height: 40px;
          color: #000;
          font-weight: 600;
          font-size: 18px;
        }

        .price {
          position: absolute;
          height: 51px;
          line-height: 51px;
          right: 41px;
          top: 30%;
          color: #db4c3f;
          font-size: 24px;
          font-weight: 600;
          display: flex;

          .unit {
            margin-left: 5px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .sys-wrapper {
    display: flex;
    align-items: center;
    border: solid 1px #eee;
    justify-content: space-between;
    background-color: #ffffff;

    .sys-item {
      width: 28%;
      margin: 20px 10px;
      text-align: center;
      cursor: pointer;
      background: #ecf5ff;
      font-size: 16px;
      transition: all 0.2s;
      height: 70px;
      line-height: 70px;

      &:hover {
        box-shadow: 0 0 20px 5px #e4e7ed;
        color: #409eff;
      }
    }
  }

  .new-house-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 0;

    .house-item {
      position: relative;
      background-color: #fff;
      box-shadow: 0 8px 20px #ddd;
      width: 300px;
      border-radius: 4px;
      border: solid 1px #f6f6f6;
      cursor: pointer;
      padding: 10px;

      .image {
        height: 200px;
      }

      .validate {
        position: absolute;
        left: 10px;
        top: 10px;
        color: #fff;
        font-size: 14px;
        background: #71d5a1;
        padding: 5px 8px;
        font-size: 13px;
        border: 1px solid #71d5a1;
      }

      .field {
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        color: #666;
      }

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 35px;
        line-height: 35px;
        color: #000;
        font-size: 18px;
      }

      .price {
        height: 35px;
        line-height: 35px;
        color: #db4c3f;
        font-size: 24px;
        font-weight: 600;
        display: flex;

        .unit {
          margin-left: 5px;
          font-size: 14px;
        }
      }
    }
  }

  .rent-info-wrapper {
    border: solid 1px #eee;
    background-color: #ffffff;
    padding: 0 10px;
    color: #666;

    .rent-info-item {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        height: 55px;
        line-height: 55px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: solid 1px #e5e5e5;

        .house {
          width: 40%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &.w-80 {
            width: 84%;
          }
        }

        .price {
          width: 16%;
        }

        .link {
          width: 11%;
          display: flex;
          align-items: center;

          .name {
            display: inline-block;
            width: 30px;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .zx-info-wrapper {
    border: solid 1px #eee;
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .zx-item {
      width: 49%;
    }
  }
}
</style>
